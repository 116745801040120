<template lang="pug">
  .setting-item-collapsed(
    @click.stop="switchComponent"
  )
    .header
      .data
        AppTooltip.inactive-red-icon(
          v-if="inactiveState.inactiveAll && !isOrganizationWithSharedInventory"
          icon="exclamation-triangle"
          :title="$t('inventory_management.inventory_for_future.warning.shop_and_all_car_classes_inactive')"
        )
        AppTooltip.inactive-red-icon(
          v-else-if="inactiveState.inactiveReference && inactiveState.inactiveSomeCarClasses && !isOrganizationWithSharedInventory"
          :title="$t('inventory_management.inventory_for_future.warning.shop_and_some_car_classes_inactive')"
        )
          FaIcon(icon="exclamation-triangle") {{ referenceTitle }}
        AppTooltip.inactive-red-icon(
          v-else-if="inactiveState.inactiveReference && !isOrganizationWithSharedInventory"
          :title="$t('inventory_management.inventory_for_future.warning.shop_inactive')"
        )
          FaIcon(icon="exclamation-triangle") {{ referenceTitle }}
        AppTooltip.inactive-red-icon(
          v-else-if="inactiveState.inactiveAllShops && isOrganizationWithSharedInventory"
          :title="$t('inventory_management.inventory_for_future.warning.group_and_all_shops_inactive')"
        )
          FaIcon(icon="exclamation-triangle") {{ referenceTitle }}
        AppTooltip.inactive-yellow-icon(
          v-else-if="inactiveState.inactiveSomeCarClasses"
          icon="exclamation-triangle"
          :title="$t('inventory_management.inventory_for_future.warning.some_car_classes_inactive')"
        )
        .shop-name(
          :class="{ inactive: inactiveState.inactiveReference }"
        ) {{ referenceTitle }}
        .car-classes(:title="carClassesTitle") {{ carClassesTitle }}
      .active
        AppToggle(
          :value="setting.active"
          :color="{ checked: '#2e9fad' }"
        )
    .body
      .days-options
        CheckboxesFilter(
          :value="setting.days_options"
          :disabled="selectedDaysOptions"
          :inactive="true"
        )
      .actions
        .expand(:id="`expand-setting-${settingId}`")
          BTooltip(
            v-if="!isExpandable"
            :target="`expand-setting-${settingId}`"
            variant="secondary"
            triggers="hover"
            placement="left"
          )
            span {{ $t('actions.unsaved_tooltip') }}
          AppIconButton.switch-component(
            :class="{ blocked: !isExpandable }"
            title="inventory_management.inventory_for_future.open"
            :iconBefore="false"
            icon="chevron-down"
            @click="switchComponent"
          )
</template>

<script>
  // misc
  import { map } from "lodash-es"
  import { isSharedInventoryEnabled as isOrganizationWithSharedInventory } from "@/helpers/organization"

  export default {
    props: {
      setting: Object,
      selectedDaysOptions: Array,
      isUnsavedChanges: Boolean,
      isNewSetting: Boolean,
      isInvalidFilters: Boolean,
      isExpandable: Boolean,
      inactiveState: {
        type: Object,
        default: () => ({
          inactiveAll: false,
          inactiveReference: false,
          inactiveAllCarClasses: false,
          inactiveSomeCarClasses: false,
          inactiveAllShops: false
        })
      }
    },

    components: {
      CheckboxesFilter: () => import("@/components/InventoryManagement/CheckboxesFilter"),
      AppToggle: () => import("@/components/elements/AppToggle"),
      SavingStatusNotice: () => import("./SavingStatusNotice"),
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    computed: {
      isOrganizationWithSharedInventory,

      settingId({ setting }) {
        return setting.id || setting._addedAt
      },

      referenceTitle({ setting }) {
        return (
          setting.reference.name ||
          this.$t(
            `inventory_management.inventory_for_future.${
              isOrganizationWithSharedInventory() ? "inventory_group_isnt_selected" : "shop_isnt_selected"
            }`
          )
        )
      },

      carClassesTitle({ setting }) {
        return map(
          [...setting.car_classes].sort((a, b) => a.order - b.order),
          "name"
        ).join(" / ")
      }
    },

    methods: {
      switchComponent(value) {
        if (!this.isExpandable) return

        this.$emit("switch-component", value)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/auto-set-inventory-for-future.sass"
  @import "@/assets/styles/mixins/common.sass"

  .setting-item-collapsed
    &, *
      cursor: pointer

    ::v-deep
      .app-checkbox.disabled
        opacity: 1
        cursor: pointer

    .header
      align-items: center
      color: $default-black
      display: flex
      font-size: 0.8rem
      justify-content: space-between
      margin-bottom: 15px

      .data
        cursor: default
        display: flex
        width: calc(100% - 50px)

        .inactive-red-icon
          color: $default-red
          ::v-deep
            svg
              color: $default-red

        .inactive-yellow-icon
          ::v-deep
            svg
              color: $default-yellow

        .shop-name
          font-weight: 600
          margin: 0 20px 0 10px
          white-space: nowrap

          &.inactive
            color: $default-red

        .car-classes
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap

          .inactive
            color: $default-red

      .active
        width: 33px

    .body
      align-items: flex-end
      display: flex
      justify-content: space-between
      transition: $transition

      .days-options
        display: flex

      .actions
        align-items: center
        display: flex
        justify-content: flex-end
        width: 40%

        .remove-setting
          color: $default-gray-medium
          cursor: pointer
          margin-right: 15px

          ::v-deep
            &> *
              margin: 0 2px

        ::v-deep
          .switch-component
            font-weight: 700

            ::v-deep
              .title
                margin-right: 5px
              .icon
                margin-right: 0

            &.blocked
              cursor: not-allowed
              opacity: 0.2
</style>
